<template>
	<section class="content-main">
		<!--基本信息-->
		<el-row>
			<el-col :span="12" style="padding-right:20px">
				<el-form :model="form" label-width="140px" :rules="formRules" ref="form">
					<el-form-item label="选择图片" prop="url">
						<el-upload class="avatar-uploader" :action="uploadurl" :data="dataToken" :show-file-list="false" :on-success="uploaderIdentityFrontSuccess">
							<img v-if="form.url" :src="form.url" class="avatar">
							<i v-else class="el-icon-plus avatar-uploader-icon">本地选择</i>
							<div v-if="form.url" @click.stop="showImg = form.url" class="seeBigImg">放大</div>
						</el-upload>
					</el-form-item>
					<el-form-item label=" ">
						<el-input v-model.trim="form.url" placeholder="网上图片" clearable></el-input>
					</el-form-item>
					<el-form-item label="访问地址" clearable>
						<el-input v-model.trim="form.link_url"></el-input>
					</el-form-item>
					<el-form-item label="说明" prop="title" clearable>
						<el-input v-model.trim="form.title"></el-input>
					</el-form-item>
					<el-form-item label="类型" prop="type">
						<el-select v-model="form.type" placeholder="请选择类型" clearable>
							<el-option v-for="i in typeList" :key="i.value" :label="i.txt" :value="i.value">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="状态" prop="status">
						<el-radio-group v-model="form.status" prop="status">
							<el-radio label="无效"></el-radio>
							<el-radio label="有效"></el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="备注" clearable>
						<el-input v-model.trim="form.remark"></el-input>
					</el-form-item>
					<el-form-item label=" ">
						<el-button type="primary" @click.native="addSubmit" :loading="Loading">添加</el-button>
						<el-button @click="goBack">取消</el-button>
					</el-form-item>
				</el-form>
			</el-col>
			<el-col :span="12">
				<img :src="showImg" class="showImg" />
			</el-col>
		</el-row>
	</section>
</template>

<script>
	import {
		addImg,
		getUploadToken,
		uploadFiles
	} from '../../api/api';

	export default {
		data() {
			return {
				//表单验证规则
				formRules: {
					url: [{ required: true, message: '请选择图片', trigger: 'blur'}],
					title: [{ required: true,	message: '请输入输入图片说明',trigger: 'blur'}],
					type: [{ required: true, message: '请选择类型', trigger: 'blur'}],
					status: [{ required: true, message: '请选择状态', trigger: 'blur'}]
				},
				typeList:[
					{
						value: 1,
						txt: 'banner'
					},
					{
						value: 2,
						txt: '广告图片'
					},
					{
						value: 3,
						txt: '通知图片'
					},
					{
						value: 4,
						txt: '非营运时间图片'
					},
					{
						value: 5,
						txt: '其他'
					},
				],
				//创建新增
				Loading: false,
				showImg: '',
				certificate_pic: [],
				//新增界面数据
				form: {
					title: '',
					url: '',
					type: 1,
					status: '无效',
					remark: '',
					link_url: ''
				},
				uploadurl: process.env.VUE_APP_Qiniu, //上传地址
				dataToken: {}, //上传文件需要携带的参数
				imgUrlHead: '',
				provinceList: [],
				cityList: [],
				userInfo: {} //创建成功获取到的用户信息
			}
		},
		watch: {
			'form.province_id': function(newVal, oldVal) {
				if (newVal != oldVal) {
					this.getCityList()
				}
			}
		},
		methods: {
			//上传图片成功回调
			uploaderIdentityFrontSuccess(response) {
				this.form.url = this.imgUrlHead + response.key;
			},
			//获取上传文件的token
			getUploadTokenM() {
				getUploadToken().then((res) => {
					this.dataToken = {
						token: res.data.uptoken
					}
					this.imgUrlHead = 'http://' + res.data.url + '/'
				});
			},
			//取消返回
			goBack() {
				this.$router.go(-1);
			},
			
			//新增
			addSubmit: function() {
				var _t = this;
				_t.$refs.form.validate((valid) => {
					if (valid) {
						_t.Loading = true;
						let para = _t.form;
						para.status = para.status == '无效'?1:2;
						addImg(para).then((res) => {
							_t.Loading = false;
							if (res.code == 1) {
								_t.goBack()
							} else {
								_t.$message.error(res.msg);
							}
						});
					}
				});
			},
		},
		mounted() {
			//获取上传文件token
			this.getUploadTokenM();
		}
	}
</script>
<style lang="scss" scoped>
	.showImg {
		max-width: 100%;
	}

	.el-form-item__content {
		display: flex;
	}

	.avatar-uploader {
		width: 150px;
		height: 150px;
		display: flex;
		align-items: center;
		margin-right: 15px;
		float: left;
		border-radius: 6px;
		overflow: hidden;
		background-color:#efefef;
		position: relative;
	}
	.el-icon-plus:before{ content:'' }
	.seeBigImg{
		position: absolute;
		right: 0px;
		bottom: 0px;
		width: 44px;
		height: 24px;
		line-height: 24px;
		text-align: center;
		border-top-left-radius: 8px;
		background-color: rgba(0,0,0,0.8);
		color: #ffffff;
	}
	.avatar-uploader .el-upload {
		display: block;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
		border: none
	}

	.avatar-uploader .el-upload img {
		width: 100%
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 20px;
		color: #8c939d;
		width: 148px;
		height: 148px;
		line-height: 148px;
		text-align: center;
		border-radius: 6px;
		background-color: #fbfdff;
		border: 1px dashed #c0ccda;
	}

	.avatar {
		width: 100%;
		display: block;
	}

	p {
		padding: 8px 0
	}
</style>
